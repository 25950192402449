.additional-info {
    margin: 20px 0;
}

.pied {
    margin: -11px;
    background: deepskyblue;
}

.additional-info p {
    margin: -11px;
    background: deepskyblue;
}

.additional-info div {
    
    background: rgb(250, 188, 74);
}
.social-icons {
    text-align: center;
}