body {
  font-family: 'Teachers', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f5f5f5;
}

.App {
  text-align: center;
  background: #fbfbfb;
  padding: 20px;
}

.banner img {
  width: 100px;
  height: auto;
}

.banner h1 {
  font-size: 2em;
  margin: -7px 0;
  width: fit-content;
}

.banner p {
  font-size: 1.2em;
  margin: 5px 0;
}

.capture-details,
.additional-info {
  margin: 20px 0;
}

.capture-details button,
.capture-details textarea,
.payment input,

.additional-info div,
.additional-info p {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  max-width: 320px;
}

textarea {
  resize: none;
}

img {
  margin: 10px 0;
  border-radius: 10px;
}

button {
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .App {
    width: 100%;
    padding: 10px;
  }
}
