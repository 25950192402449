.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #25D366; /* Couleur de WhatsApp */
    border-radius: 50%;
    box-shadow: 0 2px 10px rgba(0,0,0,0.15);
    z-index: 1000;
}

.floating-button img {
    width: 50%;
    height: auto;
}
