/* Ajouter ce CSS à votre fichier CSS principal */
.capture-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .capture-details img {
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 320px;
  }
  
  .capture-details button {
    margin-top: 10px;
  }
  
  textarea {
    margin-top: 10px;
    width: 320px;
    height: 100px;
  }
  