.detail-commande-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 282px;
    background-color: #a71717;
    color: white;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    z-index: 1000;
    padding: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
 .element.payment {
    background: antiquewhite;
    border-radius: 30px;
}
.payment{
  border: aliceblue;
  background: antiquewhite;
  border-radius: 30px;
}
  .popup-header {
    display: ruby;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .popup-footer {
    display: ruby;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .popup-header h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .close-button {
    background: black;
    border: none;
    font-size: 16px;
    color: white;
    border-radius: 50px;
    cursor: pointer;
}
  
  .detail-commande-popup p {
    text-align: center;
    margin: 2px 0;
  }
  
  .detail-commande-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
  }
  
  .detail-commande-table th, .detail-commande-table td {
    padding: 5px;
    text-align: center;
  }
  
  .separator {
    width: 100%;
    border: 1px solid white;
    margin: 10px 0;
  }
  
  .total-commande {
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  
  .tel-paie {
    font-weight: bold;
    text-align: center;
   font-size: 15px;
  }
.text-saisi {
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

  .payment-options {
    display: flex;

  }
  .payment-btn {
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px 0;
    width: 100%;
    max-width: 320px;
    
  }
  .payment-btn img {
    width: 40px;
    height: 40px;
   
    
  }
  
  .qrcode-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed yellow;
    border-radius: 15px;
    padding: 5px;
    margin: 10px 0;
  }
  
  .total-commande {
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    margin-top: 10px;
  }
  .rechercher-button {
    padding: 10px 20px;
    text-align: center;
    font-size: 16px;
    background-color: #ffc107;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    color: #141814;
    cursor: pointer;
    height: fit-content;
    transition: background-color 0.3s ease;
}

.capture-details textarea {
  border-radius: 10px;
  border: 1px solid #ddd;
  padding: 2px;
  margin: 5px 0;

  max-width: 320px;
  text-align: center;
  margin-right: 18px;

}